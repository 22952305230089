import {
    chewyFaceFrames, elonhead1, spacex_bg,
    elonBase, elonArm, elonThumb, blinkFaceFrames,
    elonRocket, wojak, comeFaceFrames,
    paypal_bg, tesla_bg, blackness, hypnoFrames, twitter_bg, 
    fadeOutFrames, rocketFingers, rocketFrames, 
    tweets, mars_bg, godMode, final
} from '../assets/loader'

import * as PIXI from 'pixi.js'

interface FaceFrames {
    left: string[]
}

let gameOver = false;

interface Level {
    index: number
    duration_seconds: number
    velocity_lower_range: number
    velocity_upper_range: number
    face: PIXI.Sprite
    background: PIXI.Sprite
    onLevelStart?: () => void
    lose_duration_seconds: number
}

function shuffleSprites(sprites) {
    const shuffled = sprites.slice(); // Copy the array to avoid modifying the original
    for (let i = shuffled.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1)); // Random index from 0 to i
        [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]]; // Swap elements
    }
    return shuffled;
}

export class GameApp {

    private app: PIXI.Application
    private base: PIXI.Sprite
    private arm: PIXI.Sprite
    private thumb: PIXI.Sprite
    private rocketFingers: PIXI.Sprite
    private boundGameLoop: (delta: number) => void

    private chewyFaceFrames: FaceFrames = chewyFaceFrames
    private currentFrame: keyof FaceFrames = 'left'
    public chewyFace: PIXI.AnimatedSprite

    public scale: number

    private hypnoFrames: FaceFrames = hypnoFrames
    public hypnoFace: PIXI.AnimatedSprite

    private blinkFaceFrames: FaceFrames = blinkFaceFrames
    public blinkFace: PIXI.AnimatedSprite

    private comeFaceFrames: FaceFrames = comeFaceFrames
    public comeFace: PIXI.AnimatedSprite

    private fadeOutFrames: FaceFrames = fadeOutFrames
    public fadeOutBg: PIXI.AnimatedSprite

    private rocketFrames: FaceFrames = rocketFrames
    public rocketAnim: PIXI.AnimatedSprite

    public fadeout?: () => void
    public animateRocket?: () => void

    public baseFace: PIXI.Sprite
    public godMode: PIXI.Sprite
    public wojakFace: PIXI.Sprite
    public paypalBg: PIXI.Sprite
    public teslaBg: PIXI.Sprite
    public spacexBg: PIXI.Sprite
    public twitterBg: PIXI.Sprite
    public ohFace: PIXI.Sprite
    public marsBg: PIXI.Sprite

    public tweets: string[] = tweets
    public tweet_sprites

    public blackness: PIXI.Sprite

    private velocityHistory: Array<number>
    private lastPosition: Object
    private levels: Array<Level>
    private currentLevelIndex: number
    private levelFrameCount: number
    private outsideRangeFrameCount: number
    private container: PIXI.Container

    private currentLevel: Level
    private nextLevel: Level
    last: number;
    animateGodMode: () => void;
    final: PIXI.Sprite;

    constructor(parent: HTMLElement, width: number, height: number) {
        // PIXI.settings.SCALE_MODE = PIXI.SCALE_MODES.NEAREST
        this.app = new PIXI.Application({ width, height, backgroundColor: 0x000000 })
        this.container = new PIXI.Container();
        this.app.stage.addChild(this.container);
        parent.replaceChild(this.app.view, parent.lastElementChild) // Hack for parcel HMR

        // init Pixi loader
        let loader = new PIXI.Loader()
        // Add user player assets
        // Load assets
        loader.add([
            elonArm,
            elonBase,
            elonRocket,
            elonThumb,
            elonhead1,
            godMode,
            wojak,
            paypal_bg,
            tesla_bg,
            mars_bg,
            twitter_bg,
            blackness,
            rocketFingers,
            final
        ])
        Object.keys(this.chewyFaceFrames).forEach(key => {
            loader.add(this.chewyFaceFrames[key])
        })
        Object.keys(this.comeFaceFrames).forEach(key => {
            loader.add(this.comeFaceFrames[key])
        })
        Object.keys(this.blinkFaceFrames).forEach(key => {
            loader.add(this.blinkFaceFrames[key])
        })
        Object.keys(this.hypnoFrames).forEach(key => {
            loader.add(this.hypnoFrames[key])
        })
        Object.keys(this.fadeOutFrames).forEach(key => {
            loader.add(this.fadeOutFrames[key])
        })
        Object.keys(this.rocketFrames).forEach(key => {
            loader.add(this.rocketFrames[key])
        })

        tweets.forEach(tweet => {
            loader.add(tweet)
        })

        loader.load(this.onAssetsLoaded.bind(this))
    }


    private onAssetsLoaded() {
        this.scale = Math.min(
            this.app.screen.height / 208, 
            this.app.screen.width / 150
        )

        this.paypalBg = PIXI.Sprite.from(paypal_bg)
        this.teslaBg = PIXI.Sprite.from(tesla_bg)
        this.teslaBg.visible = false
        this.spacexBg = PIXI.Sprite.from(spacex_bg)
        this.spacexBg.visible = false
        this.blackness = PIXI.Sprite.from(blackness)
        this.blackness.visible = false
        this.twitterBg = PIXI.Sprite.from(twitter_bg)
        this.twitterBg.visible = false
        this.marsBg = PIXI.Sprite.from(mars_bg)
        this.marsBg.visible = false

        this.final = PIXI.Sprite.from(final)
        this.final.visible = false

        this.base = PIXI.Sprite.from(elonBase)

        this.arm = PIXI.Sprite.from(elonArm)
        this.arm.position.set(30 * this.scale, 140 * this.scale)
        this.thumb = PIXI.Sprite.from(elonThumb)
        this.thumb.position.set(50 * this.scale, 140*this.scale)
        this.rocketFingers = PIXI.Sprite.from(rocketFingers)

        this.baseFace = PIXI.Sprite.from(elonhead1)

        this.chewyFace = new PIXI.AnimatedSprite(this.chewyFaceFrames[this.currentFrame].map(path => PIXI.Texture.from(path)));
        this.chewyFace.animationSpeed = 0.1;
        this.chewyFace.play();
        this.chewyFace.visible = false

        this.godMode = PIXI.Sprite.from(godMode)
        this.godMode.visible = false

        this.comeFace = new PIXI.AnimatedSprite(this.comeFaceFrames[this.currentFrame].map(path => PIXI.Texture.from(path)));
        this.comeFace.animationSpeed = 0.1;
        this.comeFace.play();
        this.comeFace.visible = false

        this.fadeOutBg = new PIXI.AnimatedSprite(this.fadeOutFrames[this.currentFrame].map(path => PIXI.Texture.from(path)));
        this.fadeOutBg.animationSpeed = 0.1;
        this.fadeOutBg.loop = false
        this.fadeOutBg.visible = false

        this.hypnoFace = new PIXI.AnimatedSprite(this.hypnoFrames[this.currentFrame].map(path => PIXI.Texture.from(path)));
        this.hypnoFace.animationSpeed = 0.1;
        this.hypnoFace.play();
        this.hypnoFace.visible = false

        this.blinkFace = new PIXI.AnimatedSprite(this.blinkFaceFrames[this.currentFrame].map(path => PIXI.Texture.from(path)));
        this.blinkFace.animationSpeed = 0.1;
        this.blinkFace.play();
        this.blinkFace.visible = false

        this.wojakFace = PIXI.Sprite.from(wojak)
        this.wojakFace.position.set(0, 0)
        this.wojakFace.visible = false

        this.rocketAnim = new PIXI.AnimatedSprite(this.rocketFrames[this.currentFrame].map(path => PIXI.Texture.from(path)));
        this.rocketAnim.animationSpeed = 0.1;

        this.tweet_sprites =  this.tweets.map(path => PIXI.Sprite.from(PIXI.Texture.from(path)));

        this.lastPosition = Object.assign({}, this.arm.position)
        this.levelFrameCount = 0
        this.outsideRangeFrameCount = 0
        this.currentLevelIndex = 0

        this.velocityHistory = []

        this.fadeout = () => {
            this.fadeOutBg.gotoAndStop(0);
            this.fadeOutBg.visible = true
            this.fadeOutBg.play()
        }


        this.last = -1
        this.animateGodMode = () => {
            this.app.ticker.add(() => {
                this.godMode.y += this.last * 1.5 * this.scale
                this.last *= -1
            })
        };
 
        // Create a function to animate the sprite
        this.animateRocket = () => {
            this.animateGodMode()
            // Define the target point (B)
            const targetX = 264 * this.scale;
            const targetY = -184 * this.scale;

            // Define the speed of the movement
            const speed = .5 * this.scale;
            // Add an update function to move the sprite
            this.app.ticker.add(() => {
                const dx = targetX - this.rocketAnim.x;
                const dy = targetY - this.rocketAnim.y;
                const distance = Math.sqrt(dx * dx + dy * dy);

                if (distance > 1) {
                    if (distance > 10) {
                        this.rocketAnim.play()
                    }
                    const angle = Math.atan2(dy, dx);
                    this.rocketAnim.x += Math.cos(angle) * speed;
                    this.rocketAnim.y += Math.sin(angle) * speed;
                } else {
                    // Stop the movement when the sprite reaches the target
                    this.godMode.visible = false
                    this.final.visible = true
                    gameOver = true
                }
            });
        };




        this.levels = [
            {
                index: 1,
                duration_seconds: .1,
                velocity_lower_range: 0,
                velocity_upper_range: 100,
                face: this.baseFace,
                background: this.paypalBg,
                onLevelStart: () => {},
                lose_duration_seconds: 20,
            },
            {
                index: 2,
                duration_seconds: 3,
                velocity_lower_range: 0,
                velocity_upper_range: 20,
                face: this.blinkFace,
                background: this.paypalBg,
                onLevelStart: () => {},
                lose_duration_seconds: 5,
            },
            {
                index: 3,
                duration_seconds: 1,
                velocity_lower_range: 0,
                velocity_upper_range: 10,
                face: this.chewyFace,
                background: this.paypalBg,
                onLevelStart: () => {},
                lose_duration_seconds: 10,
            },
            {
                index: 4,
                duration_seconds: 1,
                velocity_lower_range: 6,
                velocity_upper_range: 20,
                face: this.chewyFace,
                background: this.teslaBg,
                onLevelStart: () => {},
                lose_duration_seconds: 10,
            },
            {
                index: 5,
                duration_seconds: 1,
                velocity_lower_range: 0,
                velocity_upper_range: 2,
                face: this.comeFace,
                background: this.teslaBg,
                onLevelStart: () => {},
                lose_duration_seconds: 10,
            },
            {
                index: 7,
                duration_seconds: 3,
                velocity_lower_range: 0,
                velocity_upper_range: 10,
                face: this.comeFace,
                background: this.twitterBg,
                onLevelStart: () => {},
                lose_duration_seconds: 4,
            },
            {
                index: 8,
                duration_seconds: 3,
                velocity_lower_range: 0,
                velocity_upper_range: 10,
                face: this.chewyFace,
                background: this.twitterBg,
                onLevelStart: this.fadeout,
                lose_duration_seconds: 5,
            },
            {
                index: 9,
                duration_seconds: 3,
                velocity_lower_range: 0,
                velocity_upper_range: 10,
                face: this.hypnoFace,
                background: this.spacexBg,
                onLevelStart: () => {},
                lose_duration_seconds: 10,
            },
            {
                index: 10,
                duration_seconds: 3,
                velocity_lower_range: 0,
                velocity_upper_range: 10,
                face: this.chewyFace,
                background: this.spacexBg,
                onLevelStart: this.fadeout,
                lose_duration_seconds: 5,
            },
            {
                index: 11,
                duration_seconds: 1,
                velocity_lower_range: 0,
                velocity_upper_range: 10,
                face: this.godMode,
                background: this.marsBg,
                onLevelStart: this.animateRocket,
                lose_duration_seconds: 100,
            },
        ]

        this.currentLevel = this.levels[this.currentLevelIndex]
        this.nextLevel= this.levels[this.currentLevelIndex + 1]

        // order here determines z-index
        const sprites = [
            this.paypalBg, this.teslaBg, this.spacexBg, 
            this.twitterBg, this.marsBg, this.base, this.thumb, 
            this.rocketAnim, this.rocketFingers, this.arm,
            this.baseFace, this.chewyFace,
            this.comeFace, this.wojakFace, this.blinkFace, 
            this.fadeOutBg, this.hypnoFace, this.godMode, this.final
        ]

        sprites.map(s => s.scale.set(this.scale, this.scale))
        sprites.map(s => this.container.addChild(s))

        // Limits of where tweets can show up
        const xMin = 0
        const xMax = 75 * this.scale
        const yMin = 0
        const yMax = 180 * this.scale 

        const tweet_scale = .18 * this.scale

        this.tweet_sprites = shuffleSprites(this.tweet_sprites)
        this.tweet_sprites.map(t => {
            // randomly assign tweets a position
            // but not in the exclusion zone (the face area)
            const excludeX1 = .1 * this.scale
            const excludeY1 = 5 * this.scale
            const excludeX2 = 75 * this.scale
            const excludeY2 = 75 * this.scale
            var x: number
            var y: number

            do {
                x = Math.random() * (xMax - xMin) + xMin;
                y = Math.random() * (yMax - yMin) + yMin;
            } while (
                x >= excludeX1 && x <= excludeX2 &&
                y >= excludeY1 && y <= excludeY2
            );

            t.x = x 
            t.y = y 
            t.visible = false
            t.scale.set(tweet_scale, tweet_scale)
            this.app.stage.addChild(t)
        });

        this.app.stage.interactive = true
        this.app.stage.on("pointermove", this.moveArm)


        // Bind the gameLoop method once and store the bound version
        this.boundGameLoop = this.gameLoop.bind(this);
        this.app.ticker.add(this.boundGameLoop);
    }

    moveArm = (e) => {
        const MOUSE_ANCHOR = { x: -20 * this.scale, y: -20 * this.scale }
        let pos = e.data.global
        let MAX_X = 43 * this.scale
        let MIN_X = 25 * this.scale
        let MAX_Y = 150 * this.scale
        let MIN_Y = Math.min(Math.max(185 * this.scale - .92 * pos.x, 115*this.scale), MAX_Y)
        this.arm.position.set(
            this.box(MAX_X, MIN_X, pos.x + MOUSE_ANCHOR.x),
            this.box(MAX_Y, MIN_Y, pos.y + MOUSE_ANCHOR.y)
        )
        let THUMB_X = 20 * this.scale
        let THUMB_Y = .5 * this.scale
        this.thumb.position.set(
            this.box(MAX_X + THUMB_X, MIN_X + THUMB_X, pos.x + THUMB_X + MOUSE_ANCHOR.x),
            this.box(MAX_Y + THUMB_Y, MIN_Y + THUMB_Y, pos.y + THUMB_Y + MOUSE_ANCHOR.y)
        )
    }

    private box(max, min, value) {
        return Math.max(
            Math.min(value, max),
            min
        )
    }

    private getVelocity(oldPos, newPos) {
        const dx = newPos._x - oldPos._x
        const dy = newPos._y - oldPos._y
        const dLength = Math.sqrt(dx ** 2 + dy ** 2)
        return Math.abs(dLength)
    }

    private showNextTweet(sprites) {
        // Filter the sprites to only include those that are not currently visible
        const invisibleSprites = sprites.filter(sprite => !sprite.visible);
        // If there are no invisible sprites, do nothing
        if (invisibleSprites.length === 0) {
            console.log("game over")
            this.app.ticker.remove(this.boundGameLoop); // Remove the bound version from the ticker to stop the loop
            return;
        }
        // Set the randomly chosen invisible sprite to visible
        invisibleSprites[0].visible = true;
    }

    private setLevel() {
        const recent_velocity = this.velocityHistory.slice(-1)[0]
        if (recent_velocity < this.currentLevel.velocity_upper_range
            && recent_velocity > this.currentLevel.velocity_lower_range) {
            this.levelFrameCount += 1
            if (this.levelFrameCount >= this.currentLevel.duration_seconds * 60) {
                this.fadeOutBg.visible = false
                this.currentLevel.face.visible = false
                this.currentLevel.background.visible = false
                this.nextLevel.onLevelStart()
                this.nextLevel.face.visible = true
                this.nextLevel.background.visible = true
                this.currentLevelIndex += 1
                this.currentLevel = this.levels[this.currentLevelIndex]
                this.nextLevel = this.levels[this.currentLevelIndex + 1]
                this.levelFrameCount = 0
                this.outsideRangeFrameCount = 0
            }
        } else {
            this.outsideRangeFrameCount += 1
            if (this.outsideRangeFrameCount >= this.currentLevel.lose_duration_seconds * 60) {
                this.wojakFace.visible = true;
                this.currentLevel.face.visible = false
                if (this.currentLevelIndex > 1) {
                    const prevLevel = this.levels[this.currentLevelIndex - 1]
                    this.fadeOutBg.visible = false
                    this.currentLevel.background.visible = false
                    prevLevel.onLevelStart()
                    prevLevel.background.visible = true
                    this.currentLevel.face.visible = false
                    // Hide the wojak sprite after a period of ms 
                    setTimeout(() => {
                        this.wojakFace.visible = false;
                        prevLevel.face.visible = true
                    }, 300);
                    this.currentLevelIndex -= 1
                    this.currentLevel = this.levels[this.currentLevelIndex]
                    this.nextLevel = this.levels[this.currentLevelIndex + 1]
                } else {
                    setTimeout(() => {
                        this.wojakFace.visible = false;
                        this.currentLevel.face.visible = true
                    }, 300);
                }
                this.showNextTweet(this.tweet_sprites)
                this.outsideRangeFrameCount = 0
            }
        }
    }

    public gameLoop(delta) {
        if (gameOver) {
            console.log("game over")
            this.app.ticker.remove(this.boundGameLoop); // Remove the bound version from the ticker to stop the loop
            return;
        }
        // todo: only do this every few frames to save
        this.velocityHistory.push(this.getVelocity(this.lastPosition, this.arm.position))
        this.lastPosition = Object.assign({}, this.arm.position)
        this.setLevel()
    }
}

