import hypnoframes from "./images/Elon/hypnotized/*.png";
import final_img from "./images/Elon/final.png";
import godmode_img from "./images/Elon/godmode.png";
import fadeoutframes from "./images/fadeout/*.png";
import elonbase from "./images/Elon/basebody.png";
import elonarm from "./images/Elon/arm.png";
import elonthumb from "./images/Elon/thumb.png";
import elonrocket from "./images/Elon/rocket.png";
import head1 from "./images/Elon/base-head.png";
import wojak_img from "./images/Elon/smug_cry_wojak.png";
import chewyfaceframes from "./images/Elon/chewy/*.png";
import comefaceframes from "./images/Elon/yelling/*.png";
import blinkfaceframes from "./images/Elon/blinking/*.png";
import paypal_img from "./images/paypal_bg.png";
import spacex_img from "./images/spacex_bg.png";
import tesla_img from "./images/tesla_bg.png";
import blackness_img from "./images/blackness.png";
import twitter_img from "./images/twitter_bg.png";
import rocketfingers_img from "./images/Elon/rocket_fingers.png";
import rocketframes from "./images/Elon/rocket/*.png";
import tweet_imgs from "./images/tweets/*.jpg";
import mars_img from "./images/mars_bg.png";

export const final = final_img;
export const elonBase = elonbase;
export const godMode = godmode_img;
export const elonBaseFace = elonbase;
export const elonArm = elonarm;
export const elonThumb = elonthumb;
export const elonRocket = elonrocket;
export const elonhead1 = head1;
export const rocketFingers = rocketfingers_img;
export const wojak = wojak_img;
export const paypal_bg = paypal_img;
export const spacex_bg = spacex_img;
export const tesla_bg = tesla_img;
export const blackness = blackness_img;
export const twitter_bg = twitter_img;
export const mars_bg = mars_img;
export const tweets = Object.values(tweet_imgs);

export const hypnoFrames = {
  left: Object.values(hypnoframes),
};

export const fadeOutFrames = {
  left: Object.values(fadeoutframes),
};

export const chewyFaceFrames = {
  left: Object.values(chewyfaceframes),
};

export const comeFaceFrames = {
  left: Object.values(comefaceframes),
};

export const blinkFaceFrames = {
  left: Object.values(blinkfaceframes),
};

export const rocketFrames = {
  left: Object.values(rocketframes),
};
